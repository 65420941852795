<template>
  <v-radio-group
    v-model="model"
    :row="inline"
    @change="onChange"
    @blur="onBlur"
    :dark="darkEnabled"
  >
    <v-radio
      :disabled="radio.disabled || disabled"
      :readonly="radio.readonly || readonly"
      :label="radio.label"
      color="primario"
      :value="radio.value"
      v-for="(radio, index) in vradios"
      :key="'radio-group-' + randomId + '-' + index"
      :dark="darkEnabled"
    ></v-radio>
  </v-radio-group>
</template>

<script>
import * as _ from 'lodash'
import { mapGetters } from 'vuex'
export default {
  name: 'FormRadioGroup',
  props: {
    value: [String, Boolean, Number],
    radios: {
      type: Array,
      default: () => [],
      required: true
    },
    inline: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: () => ({
    model: '',
    randomId: Math.random() * 1000
  }),
  watch: {
    value: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && newVal != oldVal && newVal != this.model) {
          this.model = newVal
        }
      }
    }
  },
  computed: {
    ...mapGetters(['darkEnabled']),
    vradios() {
      return (this.radios || []).map(radio => {
        return {
          label: _.get(radio, 'label', ''),
          value: _.get(radio, 'value', ''),
          disabled: _.get(radio, 'disabled', false),
          readonly: _.get(radio, 'readonly', false)
        }
      })
    }
  },
  methods: {
    onChange() {
      this.$emit('change', this.model)
      this.$emit('input', this.model)
    },
    onBlur() {
      this.$emit('blur')
    }
  },
  created() {
    this.model = this.value
  }
}
</script>
