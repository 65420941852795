<template>
  <SidePopup
    :title="titulo"
    @submit="onSubmit"
    routeback="/fornecedor/configuracao/tags-adicionais"
  >
    <label class="labelTag labelMarginBottom">Descrição da tag</label>
    <FormField
      v-model="form.descricao"
      personalizedMessage="Descrição da tag é obrigatório"
      :validation="$v.form.descricao"
    />

    <div>
      <label class="labelTag labelMarginBottom">Conteúdo da tag</label>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">far fa-question-circle</v-icon>
        </template>
        O conteúdo deve ser escrita em Javascript sendo envolvida por pelas tags
        '&lt;script&gt;&lt;/script&gt;'
      </v-tooltip>
    </div>
    <FormText
      v-model="form.tag"
      :rows="10"
      personalizedMessage="O conteúdo da tag é obrigatório"
      :validation="$v.form.tag"
    />

    <div v-for="device in devices" :key="device">
      <FormCheckbox :label="device" :val="device" v-model="form.devices" />
    </div>

    <FormCheckbox label="Tag em uso" v-model="form.status" />

    <label class="labelTag">Local de execução</label>
    <FormRadioGroup v-model="form.local" inline :radios="radioTagsAdicionais" />

    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormField from '@/components/form/FormField'
import FormText from '@/components/form/FormText'
import FormCheckbox from '@/components/form/FormCheckbox'
import FormRadioGroup from '@/components/form/FormRadioGroup'
import FormButton from '@/components/form/FormButton'
import { required } from 'vuelidate/lib/validators'
import {
  TAGADICIONAL_BYID,
  TAGADICIONAL_UPDATE,
  TAGADICIONAL_CREATE
} from '@/store/actions/tagsAdicionais'

export default {
  components: {
    SidePopup,
    FormField,
    FormText,
    FormCheckbox,
    FormRadioGroup,
    FormButton
  },
  validations: {
    form: {
      descricao: { required },
      tag: { required }
    }
  },
  data() {
    return {
      titulo: 'Nova tag adicional',
      devices: ['Desktop', 'App'],
      form: {
        descricao: '',
        tag: '',
        devices: [],
        status: true,
        local: 'head'
      },
      radioTagsAdicionais: [
        { label: 'Head', value: 'head' },
        { label: 'Body', value: 'body' }
      ]
    }
  },
  created() {
    const { idfornecedor, idtagcustomizada } = this.$route.params

    if (idfornecedor && idtagcustomizada) {
      this.titulo = 'Editar tag adicional'
      this.buscarTagAdicionalParaEditar(idfornecedor, idtagcustomizada)
    } else {
      this.titulo = 'Nova tag adicional'
    }
  },
  methods: {
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      if (this.form.idtagcustomizada) {
        this.atualizarTagAdicional()
      } else {
        this.criarTagAdicional()
      }
    },
    async buscarTagAdicionalParaEditar(idfornecedor, idtagcustomizada) {
      try {
        const data = await this.$store.dispatch(TAGADICIONAL_BYID, {
          idfornecedor,
          idtagcustomizada
        })
        const devices = data.devices ? data.devices : []
        this.form = { ...data, devices }
      } catch (error) {
        if ((error && error.message) || error.err) {
          this.$vueOnToast.pop('error', error.message || error.err)
        }

        this.$vueOnToast.pop('error', error)
      }
    },
    async atualizarTagAdicional() {
      try {
        this.form.status = String(this.form.status)
        await this.$store.dispatch(TAGADICIONAL_UPDATE, {
          idfornecedor: this.form.idfornecedor,
          idtagcustomizada: this.form.idtagcustomizada,
          data: this.form
        })

        this.$vueOnToast.pop('success', 'Tag adicional atualizada com sucesso')
        this.$router.replace('/fornecedor/configuracao/tags-adicionais')
      } catch (error) {
        if ((error && error.message) || error.err) {
          this.$vueOnToast.pop('error', error.message || error.err)
        }

        this.$vueOnToast.pop('error', error)
      }
    },
    async criarTagAdicional() {
      try {
        this.form.status = String(this.form.status)
        await this.$store.dispatch(TAGADICIONAL_CREATE, {
          idfornecedor: this.$store.getters.getFornecedorId,
          data: this.form
        })

        this.$vueOnToast.pop('success', 'Tag adicional adicionada com sucesso')
        this.$router.replace('/fornecedor/configuracao/tags-adicionais')
      } catch (error) {
        if ((error && error.message) || error.err) {
          this.$vueOnToast.pop('error', error.message || error.err)
        }

        this.$vueOnToast.pop('error', error)
      }
    }
  }
}
</script>

<style scoped>
.labelTag {
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
  color: #6a6c6f;
  font-size: 16px;
  padding-right: 10px;
}

.labelMarginBottom {
  margin-bottom: 10px;
}
</style>
